<template>
  <div>
  <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="xl"
      color="dark"
      :show.sync="alertaModal"
      :closeOnBackdrop="false"
    >
      <template #header>
        <h5><CIcon :name='iconoTitulo' size='xl' class="mr-2" /> {{tituloModal}}</h5>
        <CButton class="close" aria-label="Close" @click="alertaModal=false">x</CButton>
      </template>
      <CRow>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="filtroItems"
            :fields="fields"
            :loading="Loading"
            column-filter
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            :items-per-page="5"
            hover
            small
            sorter
            pagination
             
          >
            <template #loading>
              <loading/>
            </template>
            <template #Icono="{ item }">
              <td :class="item.Estilo">
                  <CIcon :name="item.Icono" size='xl' />
              </td>
            </template>
            <template #options="{ item }">
              <td v-if="!emodal">
                <CButton
                  v-if="item.FgApplyFix"
                  size="sm"
                  color="edit"
                  v-c-tooltip="{
                    content: $t('label.edit'),
                    placement: 'top',
                  }"
                  @click="itemSeleccionado(item)"
                >
                  <CIcon name="cil-mobile-arrow-add" />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
          <CButton outline color="excel" class="mr-1" @click="onBtnExport(true)" >
              <CIcon name="cil-file-excel"/>&nbsp; XLSX
          </CButton>
          <CButton outline color="wipe" @click="alertaModal= false">
            <CIcon name="x"/>&nbsp;
            {{$t('button.exit')}}
          </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
import General from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';
import Reportes from '@/_mixins/reportes';
import { mapState } from 'vuex';

function data () {
  return {
    items: [],
    Loading: false,
    alertaModal: false,
    loadingOverlay: false,
    tituloModal: null,
    bandera: null,
    locale: 'es',
    iconoTitulo:'',
    texto:'',
    file: '',
    emodal: false
  }
}

//FUNCIONES DE METHOD
async function listarDatos () {
  this.Loading = true;
  let listado  = Array;
  let ruta     = '';
  this.items   = [];

  if (this.bandera==1) {
    ruta = "StowagePlanningAlert-by-EdiFileId";
  } else {
    ruta = "StowagePlanningError-by-EdiFileId";
  }

  let edif = this.file!=='' ? this.file : this.EdiFileId;
  await this.$http.get(ruta, { StowagePlanningId: this.StowagePlanning, EdiFileId: edif,  })
  .then(response => {
    listado = [...response.data.data];
    let icono='cil-exclamation'; 
    this.texto = this.$t('label.errors');
    let estilo = 'text-center text-danger';
    
    if (this.bandera=="1") {
      icono = 'cil-warning';
      this.texto = this.$t('label.alerts');
      estilo = 'text-center text-warning';
    }

    this.iconoTitulo = icono;
    this.locale = this.$i18n.locale;
    //this.tituloModal = this.texto+": "+this.myDataPlanningDoc.VesselName+", ETA "+DateFormater.formatDateTimeWithSlash(this.myDataPlanningDoc.Eta)+", ETD "+DateFormater.formatDateTimeWithSlash(this.myDataPlanningDoc.Etd);
    this.items = listado.map((item) => Object.assign({}, item, {
      ...item,
      Descripcion: this.locale=='es' ? item.EdiMessageSpanish : item.EdiMessageEnglish,
      DischargePort: item.DischargePort ? item.DischargePort : '',
      LoadPort: item.LoadPort ? item.LoadPort : '',
      FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
      Icono: item.FgFix ? 'cil-check' : icono,
      Estilo: item.FgFix ? 'text-center text-success' : estilo,
    }));
    
    this.Loading= false;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.Loading= false;
  });
}

function filtroItems() {
  let i=1;
  return this.items.map((item) => Object.assign({}, item, {
    Nro: i++,
    Descripcion: item.Descripcion,
    DischargePort: item.DischargePort,
    LoadPort: item.LoadPort,
    ColumnName: item.ColumnName,
    ColumnValue: item.ColumnValue,
    CodPosition: item.CodPosition,
    ContainerCode: item.ContainerCode,
    TransaLogin: item.TransaLogin,
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    Icono: item.Icono,
    FgApplyFix: item.FgApplyFix,
    FgFix: item.FgFix
  }));
}

function fields() {
  return [
    { key: 'Nro', label: '#', _style: 'width:1%; text-align:center', filter:false},
    { key: 'Descripcion', label: this.$t('label.description'), _style: 'width:18%; text-align:left',_classes:"text-left" },
    { key: 'ColumnName', label: this.$t('label.attribute'), _style: 'width:8%; text-align:center',_classes:"text-center" },
    { key: 'ColumnValue', label: this.$t('label.value'), _style: 'width:8%; text-align:center',_classes:"text-center" },
    { key: 'CodPosition', label: 'BAYSLOT', _style: 'width:8%; text-align:center',_classes:"text-center" },
    { key: 'ContainerCode', label: this.$t('label.containerCode'), _style: 'width:10%; text-align:left',_classes:"text-left"  },
    { key: 'LoadPort', label: 'POL', _style: 'width:8%; text-align:left',_classes:"text-left"  },
    { key: 'DischargePort', label: 'POD', _style: 'width:8%; text-align:left',_classes:"text-left"  },
    { key: 'TransaLogin', label:this.$t('label.user'), _style: 'width:8%; text-align:center',_classes:"text-center" },
    { key: 'FormatedDate', label:this.$t('label.FECHA'), _style: 'width:8%; text-align:center',_classes:"text-center" },
    { key: 'Icono', label:this.$t('label.correctionControl'), _style: 'width:10%', sorter: false, filter: false, _classes:"text-center" },
    { key: 'options', label: '', sorter: false, filter: false, _style: 'width:50px' }
  ];
}

function itemSeleccionado(item) {
  this.alertaModal= false;
  let valor = Object.assign(item, { modalTitle: this.texto }, { valor: 31 });
  this.$emit('errorSelect',valor);
}

async function listarDoc() {
  this.loadingOverlay = true;
  await this.$http.get('StowagePlanning-by-id', { Filter: 'ALL', StowagePlanningId: this.StowagePlanning })
  .then(response => {
    let listado = [...response.data.data][0];
    this.tituloModal = this.texto+": "+listado.VesselName+", ETA "+DateFormater.formatDateTimeWithSlash(listado.Eta)+", ETD "+DateFormater.formatDateTimeWithSlash(listado.Etd);
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

async function onBtnExport(valor) {
    if(this.filtroItems.length !== 0){
        let rowData = [];
        let _this = this.$i18n.locale;  
        for (let i = 0; i < this.filtroItems.length; i++) {
          if(_this == 'en'){
            rowData.push({
              "#": this.filtroItems[i].Nro,
              "DESCRIPTION": this.filtroItems[i].Descripcion,
              "ATTRIBUTE": this.filtroItems[i].ColumnName,
              "VALUE": this.filtroItems[i].ColumnValue,
              "BAYSLOT": this.filtroItems[i].CodPosition,
              "CONTAINER CODE": this.filtroItems[i].ContainerCode,
              "POL": this.filtroItems[i].LoadPort,
              "POD": this.filtroItems[i].DischargePort,
              "USER": this.filtroItems[i].TransaLogin,
              "DATE": this.filtroItems[i].FormatedDate,
            });
          }else{
            rowData.push({
              "#": this.filtroItems[i].Nro,
              "DESCRIPCION": this.filtroItems[i].Descripcion,
              "ATTRIBUTO": this.filtroItems[i].ColumnName,
              "VALOR": this.filtroItems[i].ColumnValue,
              "BAYSLOT": this.filtroItems[i].CodPosition,
              "CODIGO DE CONTENEDOR": this.filtroItems[i].ContainerCode,
              "POL": this.filtroItems[i].LoadPort,
              "POD": this.filtroItems[i].DischargePort,
              "USUARIO": this.filtroItems[i].TransaLogin,
              "FECHA": this.filtroItems[i].FormatedDate,
            });
          }
          
        }
        await this.getExcel(rowData,this.texto,valor);
        this.loadingOverlay = false;
    }    
}

async function visitList() {
  if(this.VisitEdiFileId == 0){
    this.$notify({
        group: 'container',
        title: '¡Error!',
        text: this.$t('label.NoLoadDistributionPlan'),
        type: "error"
    });
    return false;
  }
  
  this.Loading = true;
  let listado  = Array;
  let ruta     = '';
  this.items   = [];

  if (this.alertType=='warn') {
    ruta = "VisitAlert-by-EdiFileId";
  } else {
    ruta = "VisitError-by-EdiFileId";
  }

  await this.$http.get(ruta, { VisitId: this.VisitId, EdiFileVisitId: this.VisitEdiFileId  })
  .then(response => {
    listado = [...response.data.data];
    let icono='cil-exclamation'; 
    this.texto = this.$t('label.errors');
    let estilo = 'text-center text-danger';
    
    if (this.alertType=='warn') {
      icono = 'cil-warning';
      this.texto = this.$t('label.alerts');
      estilo = 'text-center text-warning';
    }

    this.iconoTitulo = icono;
    this.locale = this.$i18n.locale;

    this.tituloModal = this.texto+": "+this.itinerarySelected.VesselName+", ETA "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta)+", ETD "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Etd);

    this.items = listado.map((item) => Object.assign({}, item, {
      ...item,
      Descripcion: this.locale=='es' ? item.EdiMessageSpanish : item.EdiMessageEnglish,
      DischargePort: item.DischargePort ? item.DischargePort : '',
      LoadPort: item.LoadPort ? item.LoadPort : '',
      FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
      Icono: item.FgFix ? 'cil-check' : icono,
      Estilo: item.FgFix ? 'text-center text-success' : estilo,
    }));
    
    this.Loading= false;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.Loading= false;
  });
}

export default {
  name: 'alerta-modal',
  mixins: [General,Reportes],
  data,
  props: {
    modal: null,
    alertType: {
      type: String,
      default: '',
    },
    visit: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    listarDatos,
    listarDoc,
    itemSeleccionado,
    onBtnExport,
    visitList,
  },
  watch: {
    modal: async function () {
      if (this.modal) {
        if (this.modal.value!==undefined) {
          this.bandera = this.modal.label;
          this.file = this.modal.value;
          this.emodal = true;
        } else {      
          this.file = '';    
          this.bandera = this.modal;
          this.emodal = false;
        }
        
        this.alertaModal=true;
        if(!this.visit) {
          await this.listarDatos();
          await this.listarDoc();
          this.$emit('cerrarModal');
        } else {
          this.visitList();
        }
      }
    },
    alertaModal: function(newVal) {
      if(!newVal)
        this.tituloModal = '';
        this.iconoTitulo = '';
        this.$emit('cerrarModal');
    }
  },
  computed: {
    fields,
    filtroItems,
    ...mapState({
        StowagePlanning: state => state.planificacionestiba.planificacionId,
        EdiFileId: state => state.planificacionestiba.EdiFileId,
        itinerarySelected: state => state.visitas.itinerarySelected,
        VisitId: state => state.visitas.VisitId,
        VisitEdiFileId: state => state.visitas.EdiFileId,
    }),
  }
}
</script>
<style lang="scss" scoped>
.modal .modal-header button:hover{
	color: #fff;
	text-emphasis-color: #fff;
	background: transparent;
}
.center-cell {
  text-align: center;
}
 
</style>